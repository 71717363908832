<template>
  <footer class="footer">
    <div class="company container">
      <div class="pcBox">
        <div class="info">
          <ul>
            <li>关于我们</li>
            <li @click="gotoPath('/about/companyProfile')">金源达简介</li>
            <li @click="gotoPath('/about/corporateCulture')">企业文化</li>
            <li @click="gotoPath('/about/businessPartner')">合作伙伴</li>
          </ul>
          <ul>
            <li>主要业务</li>
            <li @click="gotoPath('mainBusiness', 1)">投标保函</li>
            <li @click="gotoPath('mainBusiness', 2)">履约保函</li>
            <li @click="gotoPath('mainBusiness', 3)">预付款保函</li>
            <li @click="gotoPath('mainBusiness', 4)">农民工工资保函</li>
          </ul>
          <ul>
            <li>保函查询</li>
            <li @click="gotoPath('/guaranteeInquiry')">保函查询</li>
          </ul>
          <ul>
            <li>金源达</li>
            <li @click="gotoPath('/contactUs')">联系我们</li>
          </ul>
        </div>
        <div class="companyInfo">
          <div class="left">
            <div class="phone">
              <div class="title">
                <i class="iconfont el-iconlianxidianhua1"></i>
                客服电话
              </div>
              <p>400-0898-665</p>
            </div>
            <div class="qrcode">
              <img src="../assets/pictrue/qrcode.png" alt="" />
              <p>扫一扫，关注我们</p>
            </div>
          </div>
          <div class="right">
            <div class="line"></div>
            <div class="details">
              <p>海南金源达非融资性担保有限公司</p>
              <div class="address">
                <div><i class="iconfont el-icondayinji"></i></div>
                <div class="title">传&ensp;&ensp;真：</div>
                <div>0898-65238387</div>
              </div>
              <div class="address">
                <div><i class="el-icon-location"></i></div>
                <div class="title">地&ensp;&ensp;址：</div>
                <div>
                  海南省海口市美兰区蓝天街道大英山东一路10号海阔天空国瑞城（铂仕苑）办公楼10层1-1002房
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 仅在移动端展示 -->
      <div class="H5Box">
        <div>
          <img src="../assets/pictrue/qrcode.png" alt="" />
          <p>扫一扫，关注我们</p>
          <ul>
            <li @click="gotoPath('/about/companyProfile')">关于我们</li>
            <li style="padding-left: 10px">|</li>
            <li style="padding: 0 10px" @click="gotoPath('/mainBusiness')">
              主要业务
            </li>
            <li style="padding-right: 10px">|</li>
            <li @click="gotoPath('/contactUs')">联系我们</li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  methods: {
    gotoPath (path, params) {
      // 判断是否需要携带参数
      if (params) {
        this.$router.push({
          name: path,
          params: { id: params }
        })
      } else {
        this.$router.push(path)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  background-color: #24282c;
  min-width: 1200px;
  .company {
    height: 640px;
    .pcBox {
      .info {
        display: flex;
        justify-content: space-around;
        padding-top: 64px;
        ul li {
          color: #999999;
          font-size: 16px;
          margin-top: 16px;
          cursor: pointer;
          &:first-child {
            font-size: 18px;
            color: #fff;
            margin-top: 0;
            cursor: default;
          }
        }
      }
      .companyInfo {
        display: flex;
        margin-top: 60px;
        .left {
          flex: 1;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: space-around;
          .phone {
            .title {
              display: flex;
              align-items: center;
              font-size: 20px;
              font-weight: bold;
              letter-spacing: 1px;
              i {
                margin-right: 7px;
                font-size: 30px;
              }
            }
            p {
              margin-top: 13px;
              font-size: 40px;
              font-weight: bold;
            }
          }
          .qrcode {
            img {
              width: 140px;
              height: 140px;
              background-color: #fff;
            }
            p {
              font-size: 16px;
              color: #999;
              text-align: center;
              margin-top: 3px;
            }
          }
        }
        .right {
          flex: 1;
          display: flex;
          align-items: center;
          .line {
            width: 1px;
            height: 160px;
            border-left: 1px dashed #999;
          }
          .details {
            margin-left: 64px;
            color: #999999;
            font-size: 16px;
            p {
              font-weight: bold;
              color: #fff;
              letter-spacing: 1px;
            }
            .address {
              margin-top: 24px;
              display: flex;
              i {
                color: #fff;
                margin-right: 8px;
                font-size: 18px;
              }
              .title {
                min-width: 70px;
              }
            }
          }
        }
      }
    }
    .H5Box {
      display: none;
    }
  }
}
// 利用媒体查询实现移动端
@media screen and (max-width: 768px) {
  .footer {
    min-width: 0;
    .company {
      height: 234px;
      .pcBox {
        display: none;
      }
      .H5Box {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
        color: #999;
        img {
          width: 88px;
          height: 88px;
        }
        p {
          font-size: 16px;
          text-align: center;
          margin-top: 8px;
        }
        ul {
          display: flex;
          margin-top: 16px;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
