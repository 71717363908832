<template>
  <div class="header">
    <div class="headerContent container">
      <div class="headerAuto container">
        <!-- 仅在移动端才显示的菜单按钮 -->
        <el-dropdown class="menu" trigger="click" @command="handleCommand">
          <i class="iconfont el-iconliebiao"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in list"
              :key="index"
              :class="{ active: pathActive === item.path }"
              :command="item.path"
            >
              <router-link :to="item.path">{{ item.name }}</router-link>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <img src="../assets/pictrue/logo.png" alt="" />
        <!-- PC端 -->
        <ul>
          <li :style="getLinkStyle('/')">
            <router-link to="/">首页</router-link>
            <div class="line" v-if="isCurrentPath('/')"></div>
          </li>
          <li>
            <el-dropdown>
              <span
                class="el-dropdown-link"
                :style="{
                  'font-weight': $route.path.includes('/about') ? 'bold' : '400'
                }"
              >
                关于我们
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <router-link
                    to="/about/companyProfile"
                    style="font-size: 16px"
                    >公司简介</router-link
                  >
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link
                    to="/about/corporateCulture"
                    style="font-size: 16px"
                    >企业文化</router-link
                  >
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link
                    to="/about/businessPartner"
                    style="font-size: 16px"
                    >合作伙伴</router-link
                  >
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <div class="line" v-if="$route.path.includes('/about')"></div>
          </li>
          <li :style="getLinkStyle('/mainBusiness')">
            <router-link to="/mainBusiness">主要业务</router-link>
            <div class="line" v-if="isCurrentPath('/mainBusiness')"></div>
          </li>
          <li :style="getLinkStyle('/contactUs')">
            <router-link to="/contactUs">联系我们</router-link>
            <div class="line" v-if="isCurrentPath('/contactUs')"></div>
          </li>
          <li :style="getLinkStyle('/guaranteeInquiry')">
            <router-link to="/guaranteeInquiry">保函查询</router-link>
            <div class="line" v-if="isCurrentPath('/guaranteeInquiry')"></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: [
        {
          path: '/',
          name: '首页'
        },
        {
          path: '/about/companyProfile',
          name: '公司简介'
        },
        {
          path: '/about/corporateCulture',
          name: '企业文化'
        },
        {
          path: '/mainBusiness',
          name: '主要业务'
        },
        {
          path: '/contactUs',
          name: '联系我们'
        },
        {
          path: '/guaranteeInquiry',
          name: '保函查询'
        }
      ]
    }
  },
  computed: {
    // 当前路由的path
    pathActive () {
      return this.$route.path
    }
  },
  methods: {
    handleCommand (command) {
      // 选中项改变时更新默认选中项
      this.selectedItem = command
    },
    getLinkStyle (path) {
      return {
        'font-weight': this.$route.path === path ? 'bold' : '400'
      }
    },
    isCurrentPath (path) {
      return this.$route.path === path
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  min-width: 1200px;
  background-color: #fff;
  .headerContent {
    overflow: hidden;
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.16);

    .headerAuto {
      display: flex;
      align-items: center;
      height: 80px;
      img {
        width: 172px;
        height: 74px;
      }
      ul {
        margin-left: 107px;
        display: flex;
        align-items: center;
        color: #333333;
        font-size: 20px;
        line-height: 60px;
        li {
          margin-right: 64px;
          .line {
            margin: auto;
            margin-top: -4px;
            width: 32px;
            height: 5px;
            background-color: #c4a342;
            border-radius: 4px 4px 4px 4px;
          }
          .el-dropdown-link {
            color: #666666;
            font-size: 20px;
          }
        }
      }
      .menu {
        display: none;
      }
    }
  }
}
// 利用媒体查询实现移动端
@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    padding: 0;
  }
  .header {
    height: 50px;
    overflow: hidden;
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    min-width: 0;
    // 实现细节
    .headerContent {
      .headerAuto {
        justify-content: space-between;
        height: 50px;
        padding: 0 15px;
        img {
          width: 99px;
          height: 41px;
        }
        ul {
          margin-left: 0;
          li {
            display: none;
          }
        }
        i {
          font-size: 25px;
          color: #333;
        }
        .menu {
          display: block;
          width: 30px;
          height: 30px;
          line-height: 30px;
        }
      }
    }
  }
}

// menu 菜单的样式
::v-deep .el-dropdown-menu__item {
  width: 104px;
  text-align: center;
  a {
    font-size: 14px;
    color: #999;
  }
}
.active {
  background-color: #c4a342;
  a {
    font-size: 14px;
    color: #fff;
    font-weight: bold;
  }
}
</style>
