<template>
  <div id="app">
    <!-- Header区域 -->
    <BdHeader />
    <!-- 内容区域 -->
    <div class="content">
      <router-view />
    </div>
    <!-- 底部区域 -->
    <BdFooter />
  </div>
</template>

<script>
import BdHeader from './components/bd-header.vue'
import BdFooter from './components/bd-footer.vue'
export default {
  components: { BdHeader, BdFooter }
}
</script>

<style lang="scss" scoped>
.content {
  padding-top: 80px;
}

// 利用媒体查询实现移动端
@media screen and (max-width: 768px) {
  .content {
    padding-top: 50px;
  }
}
</style>
