import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { // 首页
    path: '/',
    name: 'index',
    component: () => import('@/views/index.vue')
  },
  {
    path: '/about',
    component: () => import('@/views/about.vue'),
    children:[
      { // 公司简介
        path: 'companyProfile',
        name: 'companyProfile',
        component: () => import('@/views/about/companyProfile.vue')
      },
      { // 企业文化
        path: 'corporateCulture',
        name: 'corporateCulture',
        component: () => import('@/views/about/corporateCulture.vue')
      },
      { // 合作伙伴
        path: 'businessPartner',
        name: 'businessPartner',
        component: () => import('@/views/about/businessPartner.vue')
      }
    ]
  },
  { // 主要业务
    path: '/mainBusiness',
    name: 'mainBusiness',
    component: () => import('@/views/mainBusiness.vue')
  },
  { // 联系我们
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('@/views/contactUs.vue')
  },
  { // 保函查询
    path: '/guaranteeInquiry',
    name: 'guaranteeInquiry',
    component: () => import('@/views/guaranteeInquiry.vue')
  },
]

const router = new VueRouter({
  routes
})

// 路由切换回到最顶部
router.afterEach(() => {
  window.scrollTo(0, 0);
});

// 解决重复点击导航时，控制台出现报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
